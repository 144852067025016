import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "./router/routes";

const App = () => {
  // Avoid Right Clicking Inside App
  useEffect(() => {
    window.oncontextmenu = function (e) {
      return false;
    };
  }, []);

  return (
    <>
      <Navigate to={ROUTES.LOGIN} />
    </>
  );
};

export default App;
