import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import { SubNavbar } from "../components/SubNavbar";
import { useDispatch, useSelector } from "react-redux";
import { useClient } from "../hooks/useClient";
import { Spinner } from "../components/spinner";
import { ROUTES } from "../router/routes";
import { setCurrentUser } from "../store/features/authSlice";
import { APP_USERS, ROLES } from "../consts/AppRoles";
import { AuthGuard } from "../components/AuthGuard";

export const AppLayout = () => {
  return (
    <>
      <div className="sticky-top">
        <Navbar />
        <SubNavbar />
      </div>
      <div className="container py-3">
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      </div>
    </>
  );
};
