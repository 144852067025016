import { Link } from "react-router-dom";
import { ROUTES } from "../router/routes";
import { useActive } from "../hooks/useActive";
import { ROLES } from "../consts/AppRoles";
import { useRoutes } from "../hooks/useRoutes";

export const SubNavbar = () => {
  const { isActive } = useActive();
  const { SUB_MENU } = useRoutes();
  return (
    <>
      <nav className="navbar navbar-expand bg-light p-2 pb-0 shadow-sm">
        <div className="container">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto">
              {SUB_MENU.map((item) => (
                <>
                  {item.show && (
                    <li className="nav-item me-4">
                      <Link
                        to={item.path}
                        className={`nav-link ${
                          isActive(item.path)
                            ? "border-bottom border-primary border-3 fw-bold"
                            : ""
                        }`}
                        aria-current="page"
                      >
                        {item.name}
                      </Link>
                    </li>
                  )}
                </>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
