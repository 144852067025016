export const API_URL = {
  GET_USERS: "/users",
  GET_STATS: "/stats",
  GET_ALL_CLOSETTE: "/admin/closette",
  GET_PRODUCTS: "/products",

  // Authentication
  CLIENT_LOGIN: "auth/client/login",

  // clients
  CLIENTS: "/clients",
  CLIENT_ONBOARD: "/clients/onboard",
  CLIENT_VERIFY: "/clients/onboard/verification",
  CLIENT_GENERATE_CODE: "/clients/generate/code",
  CLIENT_UPDATE_STATUS: "/clients/update/status",
  CLIENT_RESET_PASSWORD: "/clients/reset-password",

  // usrs
  USERS: "/users",
  USERS_LIST: "/users/admin",
  USERS_UPDATE_STATUS: "/users/update/status",

  // students
  STUDENTS_LIST: "/students",
  SURVEYS: "/survey",
  SURVEYS_DELETE: "/survey/remove",
  SURVEYS_UPLOAD: "/survey/upload",
};
