import { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../router/routes";
import * as Pages from "./pages";

// Common Components
import PrivateRoute from "../components/permission/PrivateRoute";
import { Spinner } from "../components/spinner";
import { APP_USERS } from "../consts/AppRoles";

import App from "../App";
import { AppLayout } from "../layouts/AppLayout";
import { EmptyLayout } from "../layouts/EmptyLayout";
import { Login } from "../pages/auth/Login";
import { ClientOnboard } from "../pages/client/onboard/ClientOnboardForm";
import { ClientEmailVerification } from "../pages/client/onboard/ClientEmailVerification";
import AppLayoutLoader from "../layouts/AppLayoutLoader";

const ResetPasswordPage = lazy(() => import("../pages/auth/ResetPassword"));
// dashboard
const ClientRequestPage = lazy(() =>
  import("../pages/dashboard/ClientRequests")
);
const TeachersPage = lazy(() =>
  import("../pages/dashboard/users/TeachersPage")
);
const ParentsPage = lazy(() => import("../pages/dashboard/users/ParentsPage"));
const StudentPage = lazy(() =>
  import("../pages/dashboard/students/StudentPage")
);
const SurveyPage = lazy(() => import("../pages/dashboard/surveys/SurveyPage"));
const ClientOnboardSuccess = lazy(() =>
  import("../pages/client/onboard/oboardSuccess")
);

export const AppRouter = createBrowserRouter(
  [
    {
      path: ROUTES.APP_BASE,
      element: <App />,
      children: [],
    },

    {
      path: ROUTES.LOGIN,
      element: <Login />,
    },

    {
      path: ROUTES.RESET_PASSWORD,
      element: (
        <Suspense fallback={<Spinner />}>
          <ResetPasswordPage />
        </Suspense>
      ),
    },

    // client onboarding for external use
    {
      path: ROUTES.CLIENT.INDEX,
      element: (
        <Suspense fallback={<Spinner />}>
          <EmptyLayout />
        </Suspense>
      ),
      children: [
        {
          path: ROUTES.CLIENT.ONBOARD,
          element: <ClientOnboard />,
        },
        {
          path: ROUTES.CLIENT.ONBOARDING_VERIFICATION,
          element: <ClientEmailVerification />,
        },
        {
          path: ROUTES.CLIENT.ONBOARDING_SUCCESS,
          element: <ClientOnboardSuccess />,
        },
      ],
    },

    {
      path: ROUTES.DASHBOARD.INDEX,
      element: (
        <Suspense fallback={<AppLayoutLoader />}>
          <AppLayout />
        </Suspense>
      ),
      children: [
        // clients
        {
          path: ROUTES.DASHBOARD.CLIENTS.INDEX,
          element: <ClientRequestPage />,
        },

        {
          path: ROUTES.DASHBOARD.USERS.TEACHER,
          element: <TeachersPage />,
        },
        {
          path: ROUTES.DASHBOARD.USERS.PARENT,
          element: <ParentsPage />,
        },
        {
          path: ROUTES.DASHBOARD.STUDENTS.INDEX,
          element: <StudentPage />,
        },
        {
          path: ROUTES.DASHBOARD.SURVEY.INDEX,
          element: <SurveyPage />,
        },
      ],
    },
  ],
  {
    basename: ROUTES.APP_BASE,
  }
);
